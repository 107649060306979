import React from "react"
//import "./contactFormSendgrid.css"

class ContactFormSendgrid extends React.Component {
 constructor(props) {
   super(props)
   this.state = {
     buttonDisabled: true,
     message: { fromEmail: "", subject: "", body: "" },
     submitting: false,
     submitted: false,
     error: null,
   }
 }

 onClick = async event => {
    event.preventDefault()
    this.setState({ submitting: true })
    const { fromEmail, subject, body } = this.state.message

    const response = await fetch("https://thistle-wallaby-3757.twil.io/send-email", {
      method: "post",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
      },
      body: new URLSearchParams({ fromEmail, subject, body }).toString(),
    })
    if (response.status === 200) {
      this.setState({
        error: null,
        submitting: false,
        submitted: true,
        message: {
          fromEmail: "",
          subject: "",
          body: "",
        },
      })
    } else {
      const json = await response.json()
      this.setState({
        error: json.error,
        submitting: false,
      })
    }
  }

 onChange = event => {
   const name = event.target.getAttribute("name")
   this.setState({
     message: { ...this.state.message, [name]: event.target.value },
   })
 }
 /* */
 render() {
   if( this.state.submitting ) {
     return( <div>
       <p>Sending...</p>
       </div>
     );
   }
   else if( this.state.submitted ) {
     return( <div>
       <p>Thank you! Your message has been sent.</p>
       </div>
       );
   }
   return (
     <>
       <div>{this.state.error}</div>
       <form
       style={{
          display: `flex`,
          flexDirection: `column`,
          maxWidth: `500px`,
        }}
         className="font-sans"
         method="post"
       >
         <label htmlFor="fromEmail">Your email address:</label>
         <input
           className="mb-6"
           type="email"
           name="fromEmail"
           id="fromEmail"
           value={this.state.message.fromEmail}
           onChange={this.onChange}
         ></input>
         <label htmlFor="subject">Subject:</label>
         <input
           className="mb-6"
           type="text"
           name="subject"
           id="subject"
           value={this.state.message.subject}
           onChange={this.onChange}
         />
         <label htmlFor="body">Message:</label>
         <textarea
          className="mb-6"
           style={{
             height: `150px`,
           }}
           name="body"
           id="body"
           value={this.state.message.body}
           onChange={this.onChange}
         />
         <button
          className="border-2 border-pink-300 mx-4 bg-pink-200 py-2 transform transition duration-75 ease-in
                hover:bg-pink-300 hover:border-pink-600 hover:border-6 hover:scale-105"
           style={{
             marginTop: `7px`,
           }}
           type="submit"
           disabled={this.state.submitting}
           onClick={this.onClick}
         >
           Send message
         </button>
       </form>
     </>
   )
 }
}

export default ContactFormSendgrid
