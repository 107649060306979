import React from 'react';

const TrainingTopicExcerpt = ({ item, dynamicPageItem }) => {
    const topic = dynamicPageItem;
    const renderHTML = (html) => {
        return { __html: html };
    }
    return (
        <section className="">
            <div className="">
                <div className="">
                    <h1>{topic.customFields.title}</h1>

                    <hr />
                    <div className="post-content  text-red-900 text-4xl" dangerouslySetInnerHTML={renderHTML(topic.customFields.details)}></div>
                </div>
            </div>
        </section>
    );
}
export default TrainingTopicExcerpt;
