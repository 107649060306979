import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import { renderHTML } from '../agility/utils'



export default (props) => (
	<StaticQuery
		query={graphql`
        query AboutModuleQuery {
            allAgilityAboutPerson(
              filter: {customFields: {name: {eq: "Dr Kim"}}}
            )
            {
              nodes {
                    customFields {
                      description
                      name
											photoLocalImg {
												childImageSharp {
													fluid(quality: 90, maxWidth: 400, maxHeight: 400) {
														...GatsbyImageSharpFluid
													}
												}
											}
                    }
                  }
            }
          }
        `}
		render={queryData => {
			return (
				<AboutPersons persons={queryData.allAgilityAboutPerson.nodes} {...props} />
			);
		}}
	/>
)


const AboutPersons = ({ item, persons }) => {
/*
	const renderLinks = () => {
//onClick={() => alert("Hello!") }
		let links = [];
			links.push(<li className="border-current text-base rounded py-1 hover:bg-white hover:bg-opacity-40 text-black \
								border-0 pb-1 mt-1 first:mt-0 \
								md:text-sm md:border-r-2 md:last:border-0 md:border-r-0 md:pb-0 md:mt-0  md:rounded-none " ><Link className="p-2" Blah</Link></li>)
										})
		return links;
	}
*/
    return (
        <section className="" >
            <div>
                <AboutPerson persons={persons} />
            </div>
        </section>
    )
}

const AboutPerson = ({ persons }) => {
    return persons.map(person => {
        return <Person key={person.contentID} person={person} />;
    })
}

const AboutImage = ({ image, label }) => {
    let imageToRender = null;

    if(image && image.childImageSharp) {

        imageToRender = <Img className="text-center w-2/4" fluid={image.childImageSharp.fluid} alt={label} />
    }
    return imageToRender;
}
const Person = ({ person }) => {
    console.log( "Person", person)

    return (
        <section className="w-3/4">
          <div  >
            <h1 >{person.customFields.name}</h1>
						<AboutImage image={person.customFields.photoLocalImg} label={person.customFields.image ? person.customFields.image.label : `About Image`} />
            <hr />
            <div className="text-center text-blue-700">{person.customFields.description}</div>
          </div>
        </section>
    );
}
