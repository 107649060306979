import React, { Component } from 'react';
import { Link, graphql, StaticQuery } from "gatsby"

// import './GlobalHeader.css'

export default props => (
	<StaticQuery
		query={graphql`
        query GlobalHeaderQuery {
			agilityGlobalHeader(properties: {referenceName: {eq: "globalheader"}}) {
				customFields {
					siteName
					byline
				}
			}
            allAgilitySitemapNode {
                nodes {
                    languageCode
                    path
                    menuText
                    pageID
                }
            }
		}

        `}
		render={queryData => {
			const viewModel = {
				item: queryData.agilityGlobalHeader,
				menuLinks: queryData.allAgilitySitemapNode.nodes.filter(sitemapNode => {
					let isTopLevelPage = sitemapNode.path.split('/').length === 2;
					const isThisLanguage = sitemapNode.languageCode === props.languageCode;
					if(props.isMultiLanguage) {
						isTopLevelPage = sitemapNode.path.split('/').length === 3
					}
					//only return nodes from this language and top level links only
					return isThisLanguage && isTopLevelPage
				})
			}
			return (
				<GlobalHeader {...viewModel} />
			);
		}}
	/>
)


class GlobalHeader extends Component {
	constructor( props ) {
		super(props);
		this.state = { bIsMenuOpen: false }
		this.handleToggleMenu = this.handleToggleMenu.bind(this);
	}

	handleToggleMenu() {
		this.setState( prevState => ({
			bIsMenuOpen: !prevState.bIsMenuOpen
		}));
	}


	renderLinks = () => {
//onClick={() => alert("Hello!") }
		let links = [];
		this.props.menuLinks.forEach(node => {
			links.push(<li className="bg-site-blue-light  border-current text-base rounded py-1 hover:bg-site-blue-dark text-black \
								pb-1 mt-1 first:mt-0 \
								md:bg-transparent md:border-0  md:text-sm md:border-r-2 md:last:border-0 md:border-r-0 md:pb-0 md:mt-0 md:rounded-none" key={node.pageID}><Link className="p-2" to={node.path}>{node.menuText}</Link></li>)
		})
		return links;
	}


	render() {


//		<p  onClick={() => {alert("Hello!"); this.showMobileMenu() } } id="hamberder" className="md:hidden">hamberder</p>
		return (
			<header className="text-black">
			<div className={"bg-headerBG-mobile bg-cover bg-center md:bg-headerBG  h-20" + (this.state.bIsMenuOpen? "h-auto":"md:h-32 lg:h-40")}>
				<div className="flex flex-row  h-full pt-2 px-4">
					<div className="flex-grow items-stretch"></div>
					<div className="flex-none w-2/3 md:w-1/2   items-stretch">
<div className="flex flex-col justify-between h-full ">
								<div className="flex flex-col text-current font-siteName">
									<Link to="/" className=" \
										mb-1 p-1 text-2xl \
										sm:mb-1 sm:p-1 sm:text-2xl \
										md:mb-1 md:p-1 md:text-2xl \
										lg:mb-1 lg:p-1 lg:text-4xl \
										xl:mb-1 xl:p-1 xl:text-5xl">
										{this.props.item.customFields.siteName}</Link>
									<span className=" \
										hidden \
										px-1 text-base \
										md:block \
										md:px-1 md:text-lg \
										lg:px-1 lg:text-xl \
										xl:px-1 xl:text-2xl \
									 ">{this.props.item.customFields.byline}</span>
								</div>
								<div></div>
								<div className="">
									<div className="flex">
										<div className="flex-grow"></div>


										<div className="md:hidden flex-none">
											<div className="flex">
												<div className="flex-grow"></div>
												<div className="flex-none">
																	<button className="hover:text-white fill-current" onClick={this.handleToggleMenu }>
																		<svg  className="h-6 w-6 "  viewBox="0 0 256 256" >
																		 {/* HAMBERDER icon */}
																		 <g className={this.state.bIsMenuOpen? "hidden":"block"}  transform="translate(0,-41)">

																			<rect x="28" y="88.696" width="200" height="20" rx="8.8889"/>
																			<rect x="28" y="159" width="200" height="20" rx="8.8889"/>
																			<rect x="28" y="229.3" width="200" height="20" rx="8.8889"/>
																		 </g>

																		 {/* X, or close icon */}
																		 <g className={this.state.bIsMenuOpen? "block":"hidden"} transform="translate(0,-41)" >
																			<rect transform="rotate(45)" x="68.59" y="18.991" width="282.84" height="20" rx="12.571"/>
																			<rect transform="matrix(-.70711 .70711 .70711 .70711 0 0)" x="-112.43" y="200.01" width="282.84" height="20" rx="12.571"/>
																		 </g>
																		</svg>
																	</button>
												</div>
											</div>
										</div>

										<div className="hidden md:block flex-none">
												<ul className={"block py-2 md:flex uppercase mx-4 "+ (this.state.bIsMenuOpen? "block":"hidden")}>
													{this.renderLinks()}
												</ul>
										</div>

									</div>
								</div>
</div>
					</div>
				</div>
			</div>

			{/*this is the drop down menu for mobile */}
			<div className="md:hidden flex-none" >
					<ul className={"block p-2 text-right md:flex uppercase bg-gray-100 "+ (this.state.bIsMenuOpen? "block":"hidden")}>
						{this.renderLinks()}
					</ul>
			</div>
			</header>

		);
	}
}
