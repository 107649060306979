import React from 'react';
import { Link, graphql, StaticQuery } from 'gatsby'
import { cleanHTML} from '../agility/utils'
import truncate from 'truncate-html'
import './PostListing.css'
import Img from 'gatsby-image'

export default (props) => (
	<StaticQuery
		query={graphql`
        query TrainingTopicsListingModuleQuery {

					allAgilityTrainingTopicsListing {
					    nodes {
					      customFields {
					        title
					        description
					      }
					    }
					  }

					allAgilityTrainingTopic {
						nodes {
							customFields {
								title
								shortDescription
							}
							sitemapNode {
									pagePath
							}
						}
					}
        }
    `}
		render={queryData => {
			return (
				<TrainingTopicsListing sectionInfo={queryData.allAgilityTrainingTopicsListing.nodes} topics={queryData.allAgilityTrainingTopic.nodes} {...props} />
			);
		}}
	/>
)

const TrainingTopicsListing = ({ item, sectionInfo, topics }) => {
    return (
        <section className="p-4" >
            <div className="">
                <h1 className="text-4xl font-title py-2">{item.customFields.title}</h1>
								<div className="text-base font-serif pb-6">{item.customFields.description}</div>
          			<div className="">
								<TrainingTopics topics={topics} />
                </div>
            </div>
        </section>
    )
}

const TrainingTopics = ({ topics }) => {
    return topics.map(topic => {
        	return <Topic key={topic.contentID} topic={topic} />;
    	})
}

const Topic = ({ topic }) => {

    if(!topic.sitemapNode) return(<></>);
    return(
            <Link to={topic.sitemapNode.pagePath}>
					<div className="border-2 mb-6 bg-gray-50 p-4" key={topic.contentID}>
						<div className="flex flex-row">
							<div className="border h-auto w-1/4 flex-0 text-center">icon image</div>
							<div className="flex flex-col w-3/4 p-4">
	                <h2 className="font-title text-xl pb-2">{topic.customFields.title}</h2>
									<TopicExcerpt htmlContent={topic.customFields.shortDescription} />
							</div>
						</div>
					</div>
            </Link>
    )
}
/*                <PostImage image={post.customFields.imageLocalImg} label={post.customFields.image ? post.customFields.image.label : `Post Image`} />
*/


const TopicExcerpt = ({ htmlContent }) => {
    const renderHTML = () => {
        const excerpt = cleanHTML(htmlContent);
		return { __html: excerpt };
    }
    return(<p dangerouslySetInnerHTML={renderHTML()}></p>)
}
