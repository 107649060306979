import React from "react"
//import './LayoutTemplate.css'

export default ({ children }) => {
	return (
		<section className="mx-2 sm:mx-4 max-w-screen-xl \
		 		xl:mx-auto ">
			<div className="text-gray-700 text-base">
				{children}
			</div>

		</section>
	)
}
