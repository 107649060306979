import React from 'react';
import { cleanHTML} from '../agility/utils'

const PageTitle = ({ item }) => {
	const renderHTML = () => {
			const excerpt = cleanHTML(htmlContent);
			return { __html: excerpt };
	}
	const htmlContent=item.customFields.description
	return (

			<section className=
			{"p-4"
			/*
			"p-4 \
					bg-red-100 \
					sm:bg-green-100 \
					md:bg-blue-100 \
					lg:bg-red-500 \
					xl:bg-green-500 \
					2xl:bg-blue-500 \
		"*/
	} >
			<h1 className="text-4xl font-title py-2">{item.customFields.pageTitle}</h1>
			<div className="text-base font-serif prose" dangerouslySetInnerHTML={renderHTML()}></div>
		</section>
	);
}
export default PageTitle;
