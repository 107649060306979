import React from 'react';
import ContactFormSendgrid from "../components/contactFormSendgrid"

const ContactForm = ({ item }) => {

	return (
		<section className="px-4">
			<div className="bg-gray-100 p-6">
				<ContactFormSendgrid />
			</div>
		</section>
	);
}
export default ContactForm;

/*
<section className="p-4">
	<h1  className="text-4xl font-title py-2">Contact Me</h1>
	<div className="text-base font-serif pb-6">Please leave me a message below.</div>
	<div className="bg-gray-100 p-6">
	<ContactFormSendgrid />
	</div>
</section>
*/
